<template>
<div>
    <linkbutton :toplabel="title" @click="Forgot()"/>
    <!-- ACCT NOTE MODAL -->
      <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <div >
                <form ref="changepassform" v-on:keyup.enter="SaveNewPassword">
                <label class="titlelabel">{{title}}</label>

                <formentrystacked inputid="old"  label="School Email" v-model="emailaddress"  isrequired="true" />

                

                <smallbutton @click="Go()" toplabel="Forgot Password"/>
                <smallbutton @click="$refs.mymodal.closeModal()" toplabel='Cancel' buttonclass="button buttonhighlight" />

                </form>
            </div>
            <div>
                <label class="responsemessage">{{responseMessage}}</label>
            </div>
        </template>
      </actionmodal>
    </div>
</template>

<script>
import datamethods from '../../services/datamethods.js'
import smallbutton from '../controls/button.vue'
import actionmodal from './actionmodal.vue'
import formentrystacked from '../controls/entry-stacked.vue'
import linkbutton from '../controls/linkbutton.vue'
export default {
    name:'forgotpassword',
    components:{
        smallbutton,
        actionmodal,
        formentrystacked,
        linkbutton
    },
    props:{
        title:{
            type:String,
            default:'Forgot Password'
        },
        username:{
            type:String,
        },

    },
    data(){
        return{
            emailaddress:''
        }
    },

    methods:{
        Forgot(){
            this.emailaddress=this.username
            this.$refs.mymodal.openModal();
        },
        Go(){
            this.$emit('go',this.emailaddress)
            this.$refs.mymodal.closeModal()
        }
        
    
    }
}
</script>

<style>

</style>

































       