<template>
  <div class="loginDiv">
        <div >
        <form ref="loginform" v-on:keyup.enter="login">
            
                <formentrystacked inputid="user" label="School Email" v-model="username" />
                <formentrystacked inputid="pass" inputtype="password" label="Password" v-model="password" />
     
            <!-- <recaptcha @recaptchaToken="receiveRecaptchaToken" /> -->
            <div class="logininput">
                <label for="" v-if="loginMessage!=''" >{{loginMessage}}</label>
            </div>
            
            <smallbutton @click="login()" toplabel="Login"/>
            
            <div v-if="pleaseWait">
                <label for="" class="pleasewait">Please Wait...</label>
            </div> 
        </form>
        </div>
        <div>
        <label class="responsemessage">{{responseMessage}}</label>
        </div>
        <div class="margin10">
            <!-- <linkbutton @click="signUp()" toplabel="Sign Up"/>  -->
            <forgotpassword title="Forgot Password" :username="username" @go="forgotpassword"/>
            
            <!-- <linkbutton @click="forgotpassword()" toplabel="forgot password"/> -->
        </div>
<!--         <div class="margin10">
            <a  :href="newUserURL"  tabindex="-1">{{newUserText}}</a>
        </div> -->
        
      <updatepassword ref="changepass" v-show="ischangepassword" :username="username" :oldpassword="oldpassword"></updatepassword>
    </div>

        
</template>

<script>
import axios from "axios";
// import recaptcha from '../controls/recaptcha.vue'
 import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import formentrystacked from '../controls/entry-stacked.vue'
import linkbutton from '../controls/linkbutton.vue'
import smallbutton from '../controls/button.vue'
import modal from '../controls/modal.vue'
import updatepassword from './updatepassword.vue'
import forgotpassword from './forgotpassword.vue'
export default {
    name:'loginuser',
    mixins:[datamethods],
    components:{
        formentrystacked,
        linkbutton,
        smallbutton,
        modal,
        updatepassword,
        forgotpassword
    },
    computed:{
        ...mapFields([
            'pleaseWaitText',
            'isAdminUser',
            'loggedInUser',
            'loggedIn',
            'currentWallet',
            'username',
            'oldpassword',
            'currentNav',
            'password',
            'robot'

        ]),
        loginMessage(){
            if(window.LOGIN_MESSAGE==undefined){
                return ""
            }else{return window.LOGIN_MESSAGE}
        }
    },
    data(){
        return{
          
            newUserURL:window.NEW_USER_URL,
            newUserText:'Click here to create a new Account',

            pleaseWait:false,
            responseMessage:'',

            ischangepassword:false,
            myip:''
        }
    },
    mounted(){
            this.getIpAddress()
            this.recaptcha()
        },
    methods:{
        signUp(){
            this.$emit('signup')
        },
        getIpAddress(){
      
            axios.get('https://api.ipify.org/?format=text')
            .then(response=>{
                    
                this.myip=response.data
            })
                .catch(e=>{
                
                    console.log(e)
                    
                });
            //return response.data
        },
        /* receiveRecaptchaToken(token){
           // console.log('token ' + token)
            this.recaptchaToken=token
            this.robot=false
        }, */
        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login')
            //console.log(token)
            // Do stuff with the received token.
            if(token!=null){this.robot=false}
        },
        changepassword(){
            this.ischangepassword=true
            this.$refs.changepass.ChangePassword();
        },
        async forgotpassword(emailaddress){
            
            //validate
            if(emailaddress==""){
                return this.getFailureResponse("Please enter your email address and click forgot password")
            }
            let req = {
                email:emailaddress,
            }
            this.pleaseWait=true;
            this.responseMessage='';
            var response = await this.callAPI(req,'forgotpass_appurl')
            //handle response
            if(response.Successful){
                 this.responseMessage = "Please check your email for your password reset link"
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;    
        },
        forcelogin(){
            this.robot=false;
            this.login()
        },
        async login(){
            
            if(this.robot==true){ return; }
            //validate
            if(this.username=="" || this.password==""){return}
            let req = {
                email:this.username,
                password:this.password,
                ipAddress:this.myip
            }
            
           // this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'walletloginuserlimit')
            //handle response
            if(response.Successful){
                this.currentWallet =response.wallet;
                
                this.loggedInUser=response.user; //store user
                this.AddUserToAPI(this.loggedInUser)
                if(this.loggedInUser.isAdmin){
                    this.isAdminWallet=true
                    this.currentNav="wallet"
                    this.loggedIn=true;
                    
                }else{
                    
                    this.currentNav="wallet"
                    this.loggedIn=true;
                }
                this.$emit('login')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
  
        },
       
    }
}
</script>

<style>
.loginDiv{
  margin-top:60px;
  grid-area: center;
  display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size:large;
   
}
.margin10{
    margin:10px;
}
.responsemessage{
    color:red;
}
.logininput{
    max-width:250px;
    font-size:medium;
}
</style>


 <!-- ADMIN MODAL -->
      <!-- <modal ref="adminModal">
        <template v-slot:header>
          <div class="horizFlex">
            <p class="subtitlebold">Admin</p>
          </div>
          
        </template>

        <template v-slot:body>
          <p class="subtitle">Do you want to view wallets as an Admin or your own Wallet? </p>
        </template>
        
        <template v-slot:footer>
          <div>
            <smallbutton toplabel="View as Admin" @click="goToAdminLookup"/>
            <smallbutton toplabel="View my Wallet" @click="goToMyWallet"/>
          </div>
        </template>
      </modal> -->

       /* goToAdminLookup(){
            this.$refs.adminModal.closeModal()
            this.isAdminWallet=false
            this.currentNav="wallet"
            this.loggedIn=true;
        },
        goToMyWallet(){
            this.$refs.adminModal.closeModal()
            this.isAdminWallet=true
            this.currentNav="wallet"
            this.loggedIn=true;
        }, */