import Vue from 'vue'
import App from './App.vue'
import {store} from './store/store'

import GlobalEvents from 'vue-global-events'
Vue.component('GlobalEvents', GlobalEvents)

Vue.config.productionTip = false

import {VueReCaptcha} from 'vue-recaptcha-v3'
Vue.use(VueReCaptcha, { siteKey: '6LfnKzMfAAAAABMt1ZO3dHdXHyJv45GMPTgaDt2X' })

new Vue({
  store,
  render: function (h) { return h(App) },
}).$mount('#app')
