import Vue from 'vue'
import Vuex from 'vuex'

import {getField, updateField} from 'vuex-map-fields'

Vue.use(Vuex)
export const store = new Vuex.Store({
    state:{
        //general
        username:'',
        oldpassword:'',
        password:'',
        loggedInUser:{
            name:'User',
            isAdmin:false,
            walletId:0,
            user_accountId:0,
            user_personId:0,
            badgeId:0,
            LocationId:0
        },
        loggedIn:false,
        showHeader:true,
        robot:true,
        
        lastRefreshDateTime_Wallet:'',
        lastRefreshDateTime_Explorer:'',

        //wallet
        currentWallet:{},
        currentNav:'',
        photoRequirements:'',
        logoOverride:'',
        logoImageBasePath:'',
        requireLogin:true,
        
        pleaseWait:false,
        message:'',

        //url product selection
        isUrlProduct:false,
        selectedLocation:'',
        selectedProgram:'',
        selectedDate:'',
        selectedTime:'',
        selectedCost:0,
        selectedDeposit:0,

        //account product
        isAccountProduct:false,

        //
        accountTransactionId:-1,
        selectedPaymentAmount:0,

        person:{},

        selectedProductGUID:null,
        selectedProductId:-1
    },
    mutations:{
        updateField,
        resetbeforeapi(state){
            state.pleaseWait=true;
            state.message=''
        },
    },
    getters:{
        getField,
    },
    actions:{
        beforeAPI(context){
            context.commit('resetbeforeapi'); 
        },
        
    }
})