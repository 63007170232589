<template>
    <div>
    
        <form id="walletform" ref="walletform" @submit.prevent>
            <formentry :label="firstNameLabel" :isrequired='requireName' v-model="person.FirstName" :isFirstField='firstFieldYes'/>
            <formentry :label="lastNameLabel" :isrequired='requireName' v-model="person.LastName"/>
            <formentry :label="phoneLabel" :isrequired='requirePhone' inputtype="tel"  v-model="person.PhoneNumber1"/>
            <formentry :label="emailLabel" :isrequired='requireEmail' inputtype="email"  v-model="person.EmailAddress" v-if="requireEmail"/>

            <formentry :label="idLabel" :isrequired='requireID'   v-model="person.InternalID" v-if="requireID"/>
            <formentry :label="fidLabel" :isrequired='requireFID'    v-model="person.fId" v-if="showFID" :placeholder="FIDPlaceholder"/>
            <formentry :label="birthdateLabel" :isrequired='requireBirthdate' inputtype="date"  v-model="person.Birthdate" v-if="requireBirthdate"/>
            <!-- <formentry :label="badgeLabel" :isrequired='addBadge'  v-model="badgeNumber" v-if="addBadge"/> -->
             <div class="entry" v-if="ShowTuitionPaymentType">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">{{tutionPaymentTypeLabel}}</label>
                        <label class="required">*</label>
                    </div>
                
                    <select name="" id="" class='entrySelect' v-model="person.tuitionPaymentTypeId" :required="paramTrue">
                        <option v-for="tuition in tuitionPaymentTypeList" :key="tuition.id" :value="tuition.id" >
                            {{tuition.name}}
                        </option>
                    </select>
                </div>
            <div v-if="requireAddress">
                <formentry :label="address1Label" :isrequired='requireAddress' v-model="person.AddressLine1"/>
                <formentry :label="address2Label"  v-model="person.AddressLine2"/>
                <formentry :label="cityLabel" :isrequired='requireAddress' v-model="person.City"/>
                <div class="entry">
                    <div class="entryTextDiv">
                        <label class="entryLabel" for="">{{stateLabel}}</label>
                        <label class="required">*</label>
                    </div>
                
                    <select name="" id="" class='entrySelect' v-model="person.StateID" :required="requireAddress">
                        <option v-for="state in stateList" :key="state.id" :value="state.id" >
                            {{state.name}}
                        </option>
                    </select>
                </div>
                <formentry :label="zipLabel"  v-model="person.ZipCode" :isrequired='requireAddress'/>
            </div>
            <div class="entry" v-if="showReferredBy">
                <div class="entryTextDiv">
                    <label class="entryLabel" for="">{{referredByLabel}}</label>
                    <label class="required"></label>
                </div>
            
                <select name="" id="" class='entrySelect' v-model="person.referredBy" :required="paramTrue">
                    <option v-for="rb in referredByList" :key="rb.Name" :value="rb.Name" >
                        {{rb.Name}}
                    </option>
                </select>
            </div>
            <div v-if="requirePassword">
                <formentry label="Password" :isrequired='requirePassword' inputtype="password" placeholder="minimum 8 characters" v-model="person.password" minlength="8"/>
                <formentry label="Confirm Password" :isrequired='requirePassword' inputtype="password" placeholder="minimum 8 characters" v-model="person.confirmPassword" minlength="8"/>
            </div>
            <div>
                <label for="" class="required">{{requiredFieldLabel}}</label>
            </div>
            
            <!-- if we don't have a person yet, then use recaptcha -->
            <!-- <div v-if="loggedInUser.user_personId==0">
                <recaptcha @recaptchaToken="receiveRecaptchaToken" />
            </div> -->

            <tallbutton toplabel="Continue"  @click="savePerson()"/>
            
        </form>
    </div>
  
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import format from '../../style/format.js'
import datamethods from '../../services/datamethods.js'
import tallbutton from '../controls/tallbutton.vue'
// import recaptcha from '../controls/recaptcha.vue'
import formentry from '../controls/entry.vue'
export default {
    name:'personwallet',
    mixins:[datamethods, format],
    components:{
        tallbutton,
        formentry
    },
    props:{
        requireAddress:{
            type:Boolean,
            default:false
        },
         requirePhone:{
            type:Boolean,
            default:false
        },
         requireEmail:{
            type:Boolean,
            default:false
        },
         requireName:{
            type:Boolean,
            default:false
        },
         requirePassword:{
            type:Boolean,
            default:false
        },
        requireBirthdate:{
            type:Boolean,
            default:false
        },
        requireID:{
            type:Boolean,
            default:false
        },
        addBadge:{
            type:Boolean,
            default:false
        }
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'message',
            'pleaseWait',
            'robot',
            'username',
            'selectedLocation',
            'person',
            'selectedProductGUID'
        ]),
        showReferredBy(){
        
            if(window.SHOW_REFERREDBY==undefined){return false}
            else{
                var haveList = this.referredByList.length>0
                return window.SHOW_REFERREDBY=='True' && haveList
            }
        },
        referredByLabel(){
            if(window.REFERREDBY_LABEL==undefined){return "Referred By"}
            else{return window.REFERREDBY_LABEL}
        },
        idLabel(){
            var l = "Permit #"
            if(window.ID_LABEL_OVERRIDE!=undefined){
                if(window.ID_LABEL_OVERRIDE!=''){
                    l=window.ID_LABEL_OVERRIDE
                }
            }
            return l
        },
        requireFID(){
            if(window.REQUIRE_SSN==undefined){return false}
            else{return window.REQUIRE_SSN=="True"}
        },
        showFID(){
            if(window.SHOW_SSN==undefined){return false}
            else{return window.SHOW_SSN=="True"}
        },
        FIDPlaceholder(){
            var placeholder=''
            if(window.SSN_LENGTH==undefined){placeholder='last 4 digits'}
            else if(window.SSN_LENGTH==4){placeholder='last 4 digits'}
            else{placeholder=window.SSN_LENGTH + ' digits'}
            return placeholder
        },
        FIDLength(){
            var l=4
            if(window.SSN_LENGTH!=undefined){l=window.SSN_LENGTH}
            return l
        },
        ShowTuitionPaymentType(){
            
            if(window.SHOW_TUITIONPAYMNETTYPE==undefined){return false}
            else{return window.SHOW_TUITIONPAYMNETTYPE=="True"}
        }
    },
    data(){
        return{
            paramTrue:true,

          fidLabel:'SSN',
          tutionPaymentTypeLabel:'Tuition Payment Type',

          firstFieldYes:true,

          firstNameLabel:'First Name',
          lastNameLabel:'Last Name',
          phoneLabel:'Mobile Phone # (10 digits)',
          emailLabel:'Email Address',
          requiredFieldLabel:'* Required Field',
          continueButtonText:'Continue',
          passwordLabel:'Password',
          confirmPasswordLabel:'Confirm Password',
          address1Label:'Address Line 1',
          address2Label:'Address Line 2',
          cityLabel:'City',
          stateLabel:'State',
          zipLabel:'Zip',
          
          birthdateLabel:'Birthdate',
            badgeLabel:'Badge #',
          stateList:[],
          referredByList:[],
          
          tuitionPaymentTypeList:[],

          newPerson:{
            ID:0,
            FirstName:'',
            LastName:'',
            PhoneNumber1:'',
            EmailAddress:'',
            AddressLine1:'',
            AddressLine2:'',
            City:'',
            StateID:-1,
            ZipCode:'',
            referredBy:''
          },
          badgeNumber:null
        }
        
    },
    methods:{
        
        isOKFID(fid){
            if(fid==null){return true}
            if(fid==''){return true}
            fid = fid.replaceAll('-','').replaceAll('.','').replaceAll(' ','')
            console.log(fid.length)
            return fid.length==this.FIDLength
        },
        async getPerson(){
            if(this.loggedInUser.user_personId==0){
                this.person=this.newPerson
                return
            }
            let req = {
                person:{
                    ID:this.loggedInUser.user_personId
                }
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'getperson')

            //handle response
            if(response.Successful){
                this.person=response.Person
                this.person.PhoneNumber1=this.$options.filters.formatPhone(this.person.PhoneNumber1);
                this.person.walletId=this.loggedInUser.walletId //need this for the update
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;

        },
        async recaptcha() {
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()

            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('login')
            //console.log(token)
            // Do stuff with the received token.
            if(token!=null){this.robot=false}
        },
        async savePerson(){
            

             //validations
            if(this.robot==true){ return; }
            if(this.$refs.walletform.checkValidity()==false){ return;}
            if(this.requirePassword){
                if(this.person.password !=this.person.confirmPassword){
                    this.message="Your passwords do not match. Please reenter your password"
                    return
                }
            }
            if(!this.isOKFID(this.person.fId)){
                if(this.FIDLength==4){
                    this.message="Please enter the last 4 digits of your SSN"
                }else{
                    this.message="Please enter a " + this.FIDLength + ' digit SSN'
                }
                
                return
            }
            
            //stash state name
            this.storeStateName()

            //build req
            this.person.FirstName=this.capitalize(this.person.FirstName)
            this.person.LastName=this.capitalize(this.person.LastName)
            let req = {
                person:this.person,
                accountTag:window.ACCOUNT_TAG,
                addBadge:this.addBadge
            }
            //call api
            this.$store.dispatch('beforeAPI');

            if(this.person.ID!=0){
                //existing person/wallet
                var response = await this.callAPI(req,'updateperson')

                //handle response
                if(response.Successful){
                    
                    this.$emit('saved')
    
                }else{
                    this.message = response.Message
                }
            }else{
                //new person and wallet
                req.addBadge=this.addBadge
                req.badgeNumber=this.badgeNumber
                if(this.selectedLocation!=''){
                    req.locationName=this.selectedLocation
                }
                if(this.loggedInUser.LocationId!=''){
                    req.person.locationId_Primary=this.loggedInUser.LocationId
                }
                req.person.ProductGlobalKey=this.selectedProductGUID
                var response = await this.callAPI(req,'addpersonwalletaccount')

                //handle response
                if(response.Successful){
                    this.loggedInUser.user_personId=response.person.ID
                    this.loggedInUser.walletId=response.walletId
                    this.loggedInUser.user_accountId = response.accountId;
                    this.loggedInUser.badgeId = response.badgeId;
                    this.username=this.person.EmailAddress
                    this.$emit('saved')
                    

                }else{
                    this.message = response.Message
                }
            }
            this.pleaseWait=false;
        
        },

        storeStateName(){
            var StateName=''
            var stateID = this.person.StateID
            this.stateList.forEach(myFunction3)
                function myFunction3(item, index, arr) {
                    if(arr[index].id==stateID){
                        StateName=arr[index].name
                    } 
                }   
            this.person.StateName =StateName
        },

        async getStateList(){
            
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'states')

            //handle response
            if(response.Successful){
                this.stateList = response.stateList
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
         async getReferredByList(){
            
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'referredby')

            //handle response
            if(response.Successful){
                this.referredByList = response.PersonList
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async getTuitionPaymentTypeList(){
            if(!this.ShowTuitionPaymentType){return}

            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'gettuitionpaymenttype')

            //handle response
            if(response.Successful){
                this.tuitionPaymentTypeList = response.TuitionPaymentTypeList
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        /* receiveRecaptchaToken(token){
           
            this.recaptchaToken=token
            this.robot=false
        }, */
    },
    mounted(){
        this.getStateList();
        this.getReferredByList();
        this.getTuitionPaymentTypeList();
        this.getPerson();
        this.recaptcha();
    }
}
</script>

<style>

</style>