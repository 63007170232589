<template>
  <div >
      <div v-if="haveCard">
          <br>
            <label class="paymethodlabel">Payment Methods</label>
            <div v-for="item in itemList" :key="item.ID" :value="item.ID">
                <tallbutton :buttonclass="itemButtonClass(item)" :toplabel="item.Name" @click="select(item)"/>
            </div>

       </div>
        <div v-if="!addingAccount" >
            <div>
                <button :class="addCardClass" @click="startPaypal()" v-if="paypalAvailable">
                    <img :src="paypalLogoPath" class="payimg"/>
                </button>
            </div>
            <div>
                <div v-if="achAvailable">
                    <div v-if="!addingAccount" >
                        <tallbutton bottomlabel="Add a Bank Account"  :class="addCardClass"  @click="startBank()" />
                    </div> 
                </div>
            </div>
            <div>
                 <button :class="addCardClass" @click="startBankHosted()" v-if="achHostedAvailable">
                    <img :src="bankaccountLogoPath" class="payimg"/>
                </button>
            </div>
           <div>
            <button :class="addCardClass" @click="startCard()" v-if="!addingAccount">
                <img :src="bankcardLogoPath" class="payimg"/>
            </button>
           </div>
            
            
            <button :class="addCardClass" @click="startVenmo()" v-if="venmoAvailable">
                <img :src="venmoLogoPath" class="payimg"/>
            </button>
            

            <!-- <div v-if="!addingAccount">
                <tallbutton :toplabel="bankcardText"  :class="addCardClass"   @click="startCard()"/>
            </div>   -->
        </div>
            
            <div v-if="addingAccount">
                <form ref="bankform">
                    <label class="subtitleBold">Add Bank Account</label>
                    <formentry label="Bank Routing Number" inputtype="number" placeholder="maximum 9 digits" v-model="NewAccount.RoutingNumber" :isFirstField='paramYes' :isrequired='paramYes' />
                    <formentry label="Bank Account Number" inputtype="number" placeholder="maximum 15 digits" v-model="NewAccount.AccountNumber"  :isrequired='paramYes' />
                    <formentry label="Bank Account Nickname" v-model="NewAccount.Name"/>
                    <!-- <div >
                        <label class="required">{{message}}</label>
                    </div> -->
                    <tallbutton toplabel="Save" @click="saveAccount()" />
                    <tallbutton toplabel="Cancel" @click="cancelAccount()" />
                </form>
            </div>
            <div v-if="!addingAccount && allowMultipay">
                 <tallbutton toplabel="Pay with Multiple Payment Types" bottomlabel='' @click="goToMultipay()" />
              </div>
            <div v-if="!addingAccount  && allowAlternativePayment">
                 <tallbutton :toplabel="alternativePaymentText" bottomlabel='' @click="continueAfterCards_Alternative()" />
              </div>
            <div  v-if="!addingAccount">
                <tallbutton :toplabel="ContinueWithCardText" bottomlabel='(Next step: Submit Payment)' @click="continueAfterCards()" :disabled="disableContinueAfterCard"/>
            </div> 

        <newtokenmodal ref="newtoken" v-on:accept="closeMyModalOK"/>

  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import tallbutton from '../controls/tallbutton.vue'
import newtokenmodal from '../actions/newtokenmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
export default {
    name:'card',
    mixins:[datamethods],
    components:{
        tallbutton,
        newtokenmodal,
        formentry,
        linkbutton
    },
    data(){
        return{
            bankcardLogoPath:require('@/assets/cardbutton2.jpg'),
            bankaccountLogoPath:require('@/assets/bankbutton2.jpg'),
            venmoLogoPath:require('@/assets/venmobutton2.png'),
            paypalLogoPath:require('@/assets/paypalbutton2.jpg'),

            alternativePaymentText:window.ALTERNATIVE_PAYMENT_TEXT,
            allowAlternativePayment:window.ALLOW_ALTERNATIVE_PAYMENT=="True",
            autoAlternativePayment:window.AUTO_ALTERNATIVE_PAYMENT,
            
            itemList:[],

            addingAccount:false,
            addingCard:false,
            addingBank:false,
            addingHostedBank:false,
            addingPaypal:false,
            addingVenmo:false,

            NewAccount:{
                RoutingNumber:'',
                AccountNumber:''
            },

            

            selectedWalletTokenName:'',
            selectedWalletTokenId:-1,

            paramYes:true,



        }
    },
    props:{
        
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'message',
            'pleaseWait',
            'isAccountProduct'
        ]),
        allowMultipay(){
            if(window.ALLOW_MULTIPAY==undefined){return false}
            else{return window.ALLOW_MULTIPAY}
        },
        achAvailable(){
            if(window.ACH_AVAILABLE==undefined){return false}
            else{return window.ACH_AVAILABLE=="True"}
        },
        achHostedAvailable(){
            if(window.ACH_HOSTED_AVAILABLE==undefined){return false}
            else{return window.ACH_HOSTED_AVAILABLE=="True"}
        },
        paypalAvailable(){
            if(window.PAYPAL_AVAILABLE==undefined){return false}
            else{return window.PAYPAL_AVAILABLE=="True"}
        },
        venmoAvailable(){
            if(window.VENMO_AVAILABLE==undefined){return false}
            else{return window.VENMO_AVAILABLE=="True"}
        },
        allowMultipleTokens(){
            if(this.isAccountProduct){return true}
            else{return window.ALLOW_MULTIPLE_TOKENS}
        },
        showExistingTokens(){
            if(window.SHOW_EXISTING_TOKENS==undefined){return true}
            else{return window.SHOW_EXISTING_TOKENS}
        },
        noneAvailable(){
            return this.itemList.length==0 && !this.pleaseWait
        },
        disableContinueAfterCard(){
            if(this.haveCard){return false}else{return true}
        },
        haveCard(){
            return this.itemList.length>0
        },
        addCardClass(){
            if(this.haveCard){
                return "tallButton buttonhighlight"
            }else{
                return "tallButton buttonhighlight"
            }
        },
        bankcardText(){
            if(this.haveCard){
                return "Use a Different Credit or Debit Card"
            }else{
                return "Use a Credit or Debit Card"
            }
        },
        ContinueWithCardText(){
          var txt = 'Continue'
          if(this.selectedWalletTokenName!=''){
            txt=txt + ' with ' + this.selectedWalletTokenName
          }
          return txt
        },
        
    },
    methods:{
        async getList(){
            
            //validate
            if(this.loggedInUser.user_personId==0){return;}
            //setup request
            let req = {
                locationId:'0',
                personId:this.loggedInUser.user_personId,
                walletId:this.loggedInUser.walletId
            }
            //call
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'wallettoken')
            //handle response
            if(response.Successful){
                this.itemList=response.WalletTokenList;
                //select last one!
                if (this.itemList.length>0 && this.selectedWalletTokenId==-1){
                    this.select(this.itemList[this.itemList.length-1])
                    
                    //if we aren't doing mult tokens, go forward
                    if(!this.allowMultipleTokens){
                        this.continueAfterCards()
                    }

                }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        itemButtonClass(token){

            if(token.ID==this.selectedWalletTokenId){
                return "tallButton buttonhighlightborder"
            }else{
                return "tallButton buttonprimary"
            }
        },
        startCard(){
            this.addingCard=true;
            this.$refs.newtoken.openModal()
        },
        startBank(){
            this.addingBank=true;
            this.$refs.newtoken.openModal()
        },
        startBankHosted(){
            this.addingHostedBank=true;
            this.$refs.newtoken.openModal()
        },
        startVenmo(){
            this.addingVenmo=true;
            this.$refs.newtoken.openModal()
        },
        startPaypal(){
            this.addingPaypal=true;
            this.$refs.newtoken.openModal()
        },
        async addCard(){
            var windowReference = window.open('about:blank','_blank');
            //setup req
            let req = {
                locationId:'0',
                personId:this.loggedInUser.user_personId,
                walletId:this.loggedInUser.walletId
            }
            //callapi
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'addwallettoken')

            //handle response
            if(response.Successful){
                this.walletUrl=response.url;
                windowReference.location=this.walletUrl;
            }else{
                windowReference.close()
                this.message = response.Message
            }
            this.pleaseWait=false;

        },
        async addHostedAccount(tokentype){
            var windowReference = window.open('about:blank','_blank');

            this.$store.dispatch('beforeAPI');
            let req = {
                locationId:'0',
                personId:this.loggedInUser.user_personId,
                walletTokenType:tokentype
            }
            var response = await this.callAPI(req,'addwallettoken_bytype')
            
            if(response.Successful){
                this.walletUrl=response.url;
                windowReference.location=this.walletUrl;
            }else{
                this.message = response.Message
                windowReference.close()
            }
            this.pleaseWait=false;
        },
        async select(token){
            
            this.selectedWalletTokenId=token.ID
            this.selectedWalletTokenName=token.Name
            
        },
        async closeMyModalOK(){
            this.$refs.newtoken.closeModal()
            if(this.addingCard){
                this.addingCard=false
                this.addCard()
            }else if(this.addingBank){
                this.addingBank=false
                this.addingAccount=true;
            }else if(this.addingHostedBank){
                this.addingHostedBank=false
                await this.addHostedAccount('ach')
            }else if(this.addingPaypal){
                this.addingPaypal=false
                await this.addHostedAccount('paypal')
            }else if(this.addingVenmo){
                this.addingVenmo=false
                await this.addHostedAccount('venmo')
            }
        },
        cancelAccount(){
            this.addingAccount=false
            this.message=''
        },
        ClearNewAccount(){
            this.NewAccount={
                RoutingNumber:'',
                AccountNumber:''
            }
        },
        async saveAccount(){
            if(this.$refs.bankform.checkValidity()==false){return}
            let req = {
                personId:this.loggedInUser.user_personId,
                walletToken:this.NewAccount
            }
            this.$store.dispatch('beforeAPI');
            this.message=''
            var response = await this.callAPI(req,'addwallettokenach')

            //handle response
            if(response.Successful){
                this.addingAccount=false;
                this.NewAccount={
                    RoutingNumber:'',
                    AccountNumber:''
                }
                this.getList();
            }else{
               
                this.message = response.Message
            }
            this.pleaseWait=false
        },
        continueAfterCards(){
            this.$emit('select',this.selectedWalletTokenId)
        },
        continueAfterCards_Alternative(){
            this.$emit('altpayment')
        },
        goToMultipay(){
            console.log('gotomult')
            this.$emit('multipay')
        }
    },
    mounted(){
        if(this.showExistingTokens){this.getList()}
    },
    

}
</script>

<style>
.paymethodlabel{
    font-size:x-large;
    margin:20px;
}
.tallButton{
    
    color:var(--light-text-color);
    border:none;
    border-radius:10px;
    width:250px;
    height:90px;
    padding:0px;
    font-size: large;
    margin:10px;

}
.buttonhighlight{
  background-color:white;
}
.payimg{
    border:none;
   height:100%;
}
.centered{
    text-align: center;
}
</style>