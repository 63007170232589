<template>
    <div class="vertFlex">
        
        <div v-for="item in itemList" :key="item.id" :value="item.id">
            <tallbutton :toplabel="item.name" @click="select(item)" buttonclass="locButton buttonprimary" />
           
        </div>
     
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import tallbutton from '../controls/tallbutton.vue'
export default {
    name:'location',
    mixins:[datamethods],
    components:{
        tallbutton
    },
    data(){
        return{
            itemList:[]
        }
    },
    computed:{
        ...mapFields([
            'message',
            'pleaseWait'
        ]),
    },
    methods:{
        async getList(){
            this.$store.dispatch('beforeAPI');;
            var response = await this.callAPI(null,'locations')

            //handle response
            if(response.Successful){
                this.itemList= response.locationList
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        select(item){
            this.$emit('select',item)
        }
    },
    mounted(){
        this.getList()
    }

}
</script>

<style>
.locButton{
    white-space:pre-wrap;
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    min-height:90px;
    padding:5px;
    margin:10px;
    font-size: large;
      width:80%;
}
</style>