<template>
    <div class="vertFlex" >
       
        <div class="vertFlex">
            <div >
                <div class="bordered productinfo" v-if="selectedProgram!=''" >
                    <label class="bold">Item: </label>
                    <label >{{selectedProgram}}</label>
                    <label class="bold" v-if="selectedDate!=''">Item Date:</label>
                    <label v-if="selectedDate!=''">{{getProgramDateString}}</label>
                    <label class="bold">Item Cost: </label>
                    <label >{{getProgramCostString}}</label>
                  
                </div>
                <div v-if="selectedProgram==''" >
                    <label >No program selected. Please check your URL and try again </label>
                </div>
            </div>
        </div>
        <div v-if="selectedProgram!=''">
            <!-- get person and then show it -->
            <tallbutton :toplabel="confirmText" @click="confirm()"/>
        </div> 
    
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import tallbutton from '../controls/tallbutton.vue'
export default {
    name:'urlproduct',
    components:{
        tallbutton
    },
    mixins:[datamethods],
     props:{
         accountTransactionId:{
             type:Number,
         },
       
        confirmText:{
            type:String,
            default:"Continue"
        },
        skipIfNull:{
            type:Boolean,
            default:true
        }
    },
    computed:{
        ...mapFields([
            'message',
            'pleaseWait',
            'selectedProgram',
            'selectedDate',
            'selectedTime',
            'selectedCost',
            'selectedDeposit',
            'selectedLocation'
        ]),
        getProgramDateString(){
            return this.selectedDate + ' ' + this.selectedTime 
        },
        getProgramCostString(){
            var str
            if(this.selectedDeposit!=''){ 
                str = '$' + this.selectedDeposit
            }else{
                 str = '$' + this.selectedCost 
            }
            return str
        },
    },
    data(){
        return{
            
        }
    },
    methods:{
    
        confirm(){
            this.$emit('continue')
        }
    },

}
</script>

<style>
.bordered{
  background-color: white;
  border-radius:10px;
  margin:10px;
  padding:10px;
}
.multilinelabel{
  white-space: pre-wrap;
  max-width:600px;
  font-size:x-large;
}
.productinfo{
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows:1fr 1fr 1fr;
    text-align: left;
}
</style>