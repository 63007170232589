<template>
  <div >
      <div class="status">
        <div class="list">
            <p class="statuslabelbold leftjustified">Total Due:</p>
            <p class="statuslabel rightjustified">{{selectedPaymentAmount | toCurrency}}</p>
        </div>
        
        <div v-if="processed">
            <div class="horizFlex">
                <p class="statuslabelbold">Completed Payments</p>
            </div>
             
            <div >
                <div class="list" v-for="payment in paymentList" :key="payment.id" :value="payment.id">
                    <p class="listitem leftjustified indent">{{payment.tokenName}}</p>
                    <p class="listitem rightjustified">{{payment.amount | toCurrency}}</p>
                </div>
                
            </div>  
             <div class="list">  
                <p class="statuslabelbold leftjustified">Balance Remaining: </p>
                <p class="statuslabel rightjustified">{{due | toCurrency}}</p>
            </div>
        </div>
       
        
      </div>
    <div v-if="!complete">
        <p class="boldbiglabel" >How would you like to pay your balance?</p>
        <div class="horizFlexWrap" >
            <button v-for="token in itemList" :key="token.id" :value="token.id" :class="tokenClass(token)" @click="selectToken(token)">{{token.Name}}</button>
            <button @click="addingmethod=true" :class="addTokenClass()">Add Payment Method</button>
        </div>
        <div v-if="addingmethod">
            <p class="boldbiglabel">Click the Payment Method to Add</p>
            <div class="horizFlexWrap">
                <button class="addtokenbutton" @click="startPaypal()" v-if="paypalAvailable">
                    <!-- @click="addToken('PayPal Account xxxx')"' -->
                     <img :src="paypalLogoPath" class="payimg"/>
                </button>
                <button class="addtokenbutton" @click="startBankHosted()" v-if="achHostedAvailable">
                     <img :src="bankaccountLogoPath" class="payimg"/>
                </button>
                <button class="addtokenbutton" @click="startCard()" >
                     <img :src="bankcardLogoPath" class="payimg"/>
                </button>
                <button class="addtokenbutton" @click="startVenmo()" v-if="venmoAvailable">
                     <img :src="venmoLogoPath" class="payimg"/>
                </button>
                <div>
                    <linkbutton toplabel="cancel" @click="addingmethod=false"/>
                </div>
                
            </div>
        </div>
        
        <div v-if="selected">
            <p class="boldbiglabel">How much of your {{due | toCurrency}} do you want to pay with {{selectedToken.Name}}?</p>
            <input v-model="paymentAmount"/>
            <button :class="calcfeesClass" @click="calculateFees">Calculate Fees</button>
            <p v-if="calculated">Tax Amount: {{taxAmount | toCurrency}}</p>
            <p v-if="calculated">Fee Amount: {{feeAmount | toCurrency}}</p>
            <button :disabled="disablesubmit" class="paybutton" v-if="calculated" @click="submitFunds">Pay {{totalPayment | toCurrency}} with {{selectedToken.Name}}</button>
            <!-- <p v-if="processed">Nice job it worked!</p> -->
        </div>
    </div>
    <div v-if="complete" class="completeclass">
        <button class="finishbutton" @click="alldone()">
             <div >
                <p>Payment Complete</p>
                <p>Go To Receipt</p>
             </div>
             
        </button>
    </div>
    
    <div class="gosinglelabel">
       <!--  <hr>
        <div>
            <linkbutton  toplabel="reset" @click="reset()"/>
        </div> -->
        
        <!-- <linkbutton v-if="!defaultMultipay"  toplabel="Pay with Single Payment Type" @click="gotoSinglePay()"/> -->
    </div>
      <newtokenmodal ref="newtoken" v-on:accept="closeMyModalOK"/>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import tallbutton from '../controls/tallbutton.vue'
import newtokenmodal from '../actions/newtokenmodal.vue'
import formentry from '../controls/entry.vue'
import linkbutton from '../controls/linkbutton.vue'
import format from '../../style/format.js'
export default {
    name:'multipay',
    mixins:[datamethods, format],
    components:{
        tallbutton,
        newtokenmodal,
        formentry,
        linkbutton
    },
    data(){
        return{
            disablesubmit:false,

            selected:false,
            calculated:false,
            processed:false,
            addingmethod:false,
            due:0,
            feeAmount:0,
            totalPayment:0,
            paymentAmount:0,
            taxAmount:0,
            selectedToken:{
                ID:0,
                Name:''
            },
            complete:false,

            tokenList:[],
            paymentList:[],
            calcfeesClass:'calcfees2 highlighted',

            bankcardLogoPath:require('@/assets/cardbutton2.jpg'),
            bankaccountLogoPath:require('@/assets/bankbutton2.jpg'),
            venmoLogoPath:require('@/assets/venmobutton2.png'),
            paypalLogoPath:require('@/assets/paypalbutton2.jpg'),

            alternativePaymentText:window.ALTERNATIVE_PAYMENT_TEXT,
            allowAlternativePayment:window.ALLOW_ALTERNATIVE_PAYMENT=="True",
            

            itemList:[],

            addingAccount:false,
            addingCard:false,
            addingBank:false,
            addingHostedBank:false,
            addingPaypal:false,
            addingVenmo:false,

            NewAccount:{
                RoutingNumber:'',
                AccountNumber:''
            },

            

            selectedWalletTokenName:'',
            selectedWalletTokenId:-1,

            paramYes:true,

            //payments
            accountList:[]

        }
    },
    props:{
        
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'pleaseWait',
            'message',
            'isAccountProduct',
            'selectedPaymentAmount',
            'accountTransactionId'
        ]),
        
        allowMultipay(){
            if(window.ALLOW_MULTIPAY==undefined){return false}
            else{return window.ALLOW_MULTIPAY=="True"}
        },
        defaultMultipay(){
            if(window.DEFAULT_MULTIPAY==undefined){return false}
            else{return window.DEFAULT_MULTIPAY}
        },
        achAvailable(){
            if(window.ACH_AVAILABLE==undefined){return false}
            else{return window.ACH_AVAILABLE=="True"}
        },
        achHostedAvailable(){
            if(window.ACH_HOSTED_AVAILABLE==undefined){return false}
            else{return window.ACH_HOSTED_AVAILABLE=="True"}
        },
        paypalAvailable(){
            if(window.PAYPAL_AVAILABLE==undefined){return false}
            else{return window.PAYPAL_AVAILABLE=="True"}
        },
        venmoAvailable(){
            if(window.VENMO_AVAILABLE==undefined){return false}
            else{return window.VENMO_AVAILABLE=="True"}
        },
        allowMultipleTokens(){
            if(this.isAccountProduct){return true}
            else{return window.ALLOW_MULTIPLE_TOKENS}
        },
        showExistingTokens(){
            if(window.SHOW_EXISTING_TOKENS==undefined){return true}
            else{return window.SHOW_EXISTING_TOKENS}
        },
        noneAvailable(){
            return this.itemList.length==0 && !this.pleaseWait
        },
        disableContinueAfterCard(){
            if(this.haveCard){return false}else{return true}
        },
        haveCard(){
            return this.itemList.length>0
        },
        addCardClass(){
            if(this.haveCard){
                return "tallButton buttonhighlight"
            }else{
                return "tallButton buttonhighlight"
            }
        },
        
        
        
    },
    methods:{
        reset(){
            this.selected=false
            this.calculated=false
            this.processed=false
            this.addingmethod=false
            this.due=0
            this.feeAmount=0
            this.totalPayment=0
            this.paymentAmount=0
            this.selectedToken={
                ID:0,
                Name:''
            }
            this.complete=false

            this.tokenList=[]
            this.paymentList=[]
            this.calcfeesClass='calcfees2 highlighted'
        },
        alldone(){
            console.log('alldone')
            this.$emit('complete')
        },
        calculateFees(){
            this.calculated=false;
            if(Number(this.paymentAmount)> this.due){
                console.log('toomuch')
                this.message="Please enter an amount less than or equal to $" + this.due.toFixed(2);
                return;
            }
            this.GetTax()
            //this.GetFees()
            
            
        },
        async GetTax(){
 
            //create req
            let req = {
                Product:{
                    id:-1,
                    price:this.paymentAmount
                }
                
            }
            //call
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'gettax')
            //handle response
            if(response.Successful){
                var taxamount = response.responseDouble
                this.taxAmount = taxamount
                console.log(taxamount)
               
            
                this.GetFees();//now we can add the fees!
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
            },
        addToken(tokenname){
            var newtoken=
                {
                    id:1,
                    name:tokenname,
                    selected:false
                }
            this.tokenList.push(newtoken)
            this.selectToken(this.tokenList[this.tokenList.length-1])
            this.addingmethod=false
        },
        addTokenClass(){
            if(this.tokenList.length==0){
                console.log('token seltoken')
                return "token highlighted"}
            else{return "token regular"}
        },
        
        tokenClass(token){
            if(token.selected){
                return "token regular seltoken"
            }else{
                return "token regular unselected"
            }
        },
        paybuttonClass(){
            return "token regular"
        },
        selectToken(token){
            this.itemList.forEach(myFunction2)
            function myFunction2(item, index, arr) {
                let t=arr[index]
                if(t.ID==token.ID){t.selected=true}
                else{t.selected=false}
            } 
            //token.selected=true;
            this.selectedToken=token
            if(this.selectedToken.ID>0){
                this.selected=true
                this.addingmethod=false
            }

        },
        afterprocesscard(){
            this.processed=true;
            this.selected=false;
            this.calculated=false;
            
            var newpmt =
                {
                    id:1,
                    tokenName:this.selectedToken.Name,
                    amount:this.paymentAmount + this.feeAmount + this.taxAmount
                }
            this.paymentList.push(newpmt)

            this.due=this.due-this.paymentAmount;
            this.paymentAmount=this.due.toFixed(2);

            if(this.due==0){
                this.complete=true;
            }
        },
        gotoSinglePay(){
            this.$emit('singlepay')
        },
        async getList(isrefresh){
            if(!this.addingmethod && isrefresh) {return;}
            //validate
            if(this.loggedInUser.user_personId==0){return;}
            //setup request
            let req = {
                locationId:'0',
                personId:this.loggedInUser.user_personId,
                walletId:this.loggedInUser.walletId
            }
            //call
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'wallettoken')
            //handle response
            if(response.Successful){
                this.itemList=response.WalletTokenList;
                //select last one!
                if(this.addingmethod){
                    console.log('added method')
                    if (this.itemList.length>0 ){
                        this.selectToken(this.itemList[this.itemList.length-1])
                    }
                    
                }else{
                    console.log('not added method')
                    this.selectToken(this.selectedToken)
                }
                
                
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        itemButtonClass(token){

            if(token.ID==this.selectedWalletTokenId){
                return "tallButton buttonhighlightborder"
            }else{
                return "tallButton buttonprimary"
            }
        },
        startCard(){
            this.addingCard=true;
            this.$refs.newtoken.openModal()
        },
        startBank(){
            this.addingBank=true;
            this.$refs.newtoken.openModal()
        },
        startBankHosted(){
            this.addingHostedBank=true;
            this.$refs.newtoken.openModal()
        },
        startVenmo(){
            this.addingVenmo=true;
            this.$refs.newtoken.openModal()
        },
        startPaypal(){
            this.addingPaypal=true;
            this.$refs.newtoken.openModal()
        },
        async addCard(){
            var windowReference = window.open('about:blank','_blank');
            //setup req
            let req = {
                locationId:'0',
                personId:this.loggedInUser.user_personId,
                walletId:this.loggedInUser.walletId
            }
            //callapi
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'addwallettoken')

            //handle response
            if(response.Successful){
                this.walletUrl=response.url;
                windowReference.location=this.walletUrl;
            }else{
                windowReference.close()
                this.message = response.Message
            }
            this.pleaseWait=false;

        },
        async addHostedAccount(tokentype){
            var windowReference = window.open('about:blank','_blank');

            this.$store.dispatch('beforeAPI');
            let req = {
                locationId:'0',
                personId:this.loggedInUser.user_personId,
                walletTokenType:tokentype
            }
            var response = await this.callAPI(req,'addwallettoken_bytype')
            
            if(response.Successful){
                this.walletUrl=response.url;
                windowReference.location=this.walletUrl;
            }else{
                this.message = response.Message
                windowReference.close()
            }
            this.pleaseWait=false;
        },
        async select(token){
            
            this.selectedWalletTokenId=token.ID
            this.selectedWalletTokenName=token.Name
            
        },
        async closeMyModalOK(){
            this.$refs.newtoken.closeModal()
            if(this.addingCard){
                this.addingCard=false
                this.addCard()
            }else if(this.addingBank){
                this.addingBank=false
                this.addingAccount=true;
            }else if(this.addingHostedBank){
                this.addingHostedBank=false
                await this.addHostedAccount('ach')
            }else if(this.addingPaypal){
                this.addingPaypal=false
                await this.addHostedAccount('paypal')
            }else if(this.addingVenmo){
                this.addingVenmo=false
                await this.addHostedAccount('venmo')
            }
        },
        cancelAccount(){
            this.addingAccount=false
            this.message=''
        },
        ClearNewAccount(){
            this.NewAccount={
                RoutingNumber:'',
                AccountNumber:''
            }
        },
        async saveAccount(){
            if(this.$refs.bankform.checkValidity()==false){return}
            let req = {
                personId:this.loggedInUser.user_personId,
                walletToken:this.NewAccount
            }
            this.$store.dispatch('beforeAPI');
            this.message=''
            var response = await this.callAPI(req,'addwallettokenach')

            //handle response
            if(response.Successful){
                this.addingAccount=false;
                this.NewAccount={
                    RoutingNumber:'',
                    AccountNumber:''
                }
                this.getList(false);
            }else{
               
                this.message = response.Message
            }
            this.pleaseWait=false
        },
        continueAfterCards(){
            this.$emit('select',this.selectedWalletTokenId)
        },
        continueAfterCards_Alternative(){
            this.$emit('altpayment')
        },
        goToMultipay(){
            this.$emit('multipay')
        },


        //pay methods
        async initializeEnrollment(){
            
            //setup request
            let req = {
                walletId:this.loggedInUser.walletId,
                accountId:this.loggedInUser.user_accountId,
                accountTransactionId:this.accountTransactionId,
                locationId:this.loggedInUser.locationId,
                amount:this.selectedPaymentAmount
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response
            response = await this.callAPI(req,'initializeenroll')
            
            //handle response
            if(response.Successful){
                this.GetAccounts()
                this.getList(false);
            }else{
                this.message="Your transaction failed to process."
            }
            this.pleaseWait=false;
        },

        totalAmount(account){
            return account.Amount + account.FeeAmount + account.TaxAmount
        },
        async GetAccounts(){
            let amt = this.selectedPaymentAmount;
            let token = this.selectedWalletTokenId;

            //validate
            if(this.loggedInUser.user_personId==0){return;}
            //create req
            let req = {
                locationId:'0',
                personId:this.loggedInUser.user_personId,
                walletId:this.loggedInUser.walletId
            }
            //call
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'account')
            //handle response
            if(response.Successful){
                this.accountList=response.AccountList;
                this.accountList.forEach(myFunction3)
                function myFunction3(item, index, arr) {
                    if(arr[index].Amount==undefined){arr[index].Amount=amt}
                    arr[index].TokenID=token
                    if(arr[index].FeeAmount==undefined){arr[index].FeeAmount='0'}
                    if(arr[index].TaxAmount==undefined){arr[index].TaxAmount='0'}
                }  
                console.log('set amt due to the account bal')
                this.selectedPaymentAmount=this.accountList[0].amountDue; 
                this.due=this.selectedPaymentAmount;
                this.paymentAmount=this.due.toFixed(2);
                if (this.selectedPaymentAmount==0 ){this.complete=true;}
                //this.GetFees();//now we can add the fees!
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
      async GetFees(){
        this.accountList[0].TokenID=this.selectedToken.ID
        this.accountList[0].Amount=this.paymentAmount
        this.accountList[0].TaxAmount = this.taxAmount
        let req = {
            initialAccountFundList:this.accountList
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'getfee')
        //handle response
        if(response.Successful){
          this.accountList=response.initialAccountFundList
            this.calculated=true;
            this.calcfeesClass="calcfees2 regular"

            this.feeAmount = this.accountList[0].FeeAmount
            this.totalPayment=Number(this.paymentAmount) + Number(this.feeAmount) + Number(this.taxAmount)
          //this.getTransactionString('accountsummary',true)
          
        }else{
          this.message = response.Message
        }
        this.pleaseWait=false;
      },
       async submitFunds(){
            this.disablesubmit=true
            this.accountList[0].Amount=this.paymentAmount
            console.log("submit funds")
          
            this.$store.dispatch('beforeAPI');
            let req = {
                    initialAccountFundList:this.accountList
                }
            var response = await this.callAPI(req,'paymenttoaccountlistnodup')

            if(response.Successful){
            
                this.afterprocesscard()
            }else{
                this.message = response.Message
                this.disablesubmit=false;
            }
            this.pleaseWait=false;
            
    },
      /* async submitFunds(){
          console.log('submitfunds')
        //setup request
        let req = {
            walletId:this.loggedInUser.walletId,
            accountId:this.loggedInUser.user_accountId,
            initialAccountFundList:this.accountList,
            accountTransactionId:this.accountTransactionId,
            locationId:this.loggedInUser.locationId
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response
        if(this.isUrlProduct || this.isAccountProduct){
            response = await this.callAPI(req,'paymenttoaccountlistenroll')
        }else{
            response = await this.callAPI(req,'paymenttoaccountlist_productenroll')
        }
        
        //handle response
        if(response.Successful){
          this.accountList=response.initialAccountFundList
          this.accountTransactionId = response.initialAccountFundList[0].transactionID
          this.$emit('fundssubmitted')
        }else{
          this.message="Your transaction failed to process."
        }
        this.pleaseWait=false;
      }, */
    },
    mounted(){
        if(this.showExistingTokens){this.getList(false)}
        
    },
    

}
</script>

<style>
.addtokenbutton{
    border:none;
    height:50px;

}
.token{
    font-size:large;
    margin:5px;
    color:var(--light-text-color);
    border:none;
    border-radius:2px;
    padding:5px;
    min-height:50px;
    min-width:200px;
}
.calcfees2{
    font-size:medium;
    margin:5px;
    color:var(--light-text-color);
    border:none;
    border-radius:2px;
    padding:5px;
    min-height:30px;
}
.paybutton{
    font-size:large;
    margin:5px;
    color:var(--light-text-color);
    background-color:var(--highlight-button-color);
    border:none;
    border-radius:2px;
    padding:5px;
    min-height:40px;
}
.finishbutton{
     font-size:large;
    margin:5px;
    color:var(--light-text-color);
    background-color:var(--highlight-button-color);
    border:none;
    border-radius:2px;
    padding:5px;
    min-height:40px;
    width:180px;
}
.completeclass{
    margin-top:40px;
}
.unselected{
    border:none;
}
.regular{
    background-color:var(--button-color);
}
.highlighted{
    background-color:var(--highlight-button-color);
}
.seltoken{
    
    border:4px solid var(--highlight-button-color);
}
.list{
    padding:0px;
    width:95%;
    display:grid;
    grid-template-columns: 3fr 1fr;
}
.listitem{
    margin:5px;
}
.rightjustify{
    text-align: right;
}
.leftjustify{
    text-align:left; 
}
.indent{
    margin-left:20px;
}
.statuslabel{
    margin:5px;
}
.statuslabelbold{
    margin:5px;
    font-weight: bold;
}
.boldbiglabel{
    font-size:larger;
}
.gosinglelabel{
    margin-top:80px;
    margin-bottom:20px;
}
.status{
    border:1px solid gray;
    padding:0px;
    margin-top:5px;
}
.paymethodlabel{
    font-size:x-large;
    margin:20px;
}
.tallButton{
    
    color:var(--light-text-color);
    border:none;
    border-radius:10px;
    width:250px;
    height:90px;
    padding:0px;
    font-size: large;
    margin:10px;

}
.buttonhighlight{
  background-color:white;
}
.payimg{
    border:none;
   height:100%;
}
</style>