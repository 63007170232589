<template>
  <actionmodal ref="mymodal"> 
        <template v-slot:body>
            <div class="main">
                <form ref="confirmform" >
                <label class="pictitle">Picture Requirements</label>
                <br>
                <label v-html="photoRequirements"/>
 
                <div>
                    <input type="checkbox" required="required" ref="checkme"/>
                    <label class="checklabel">I confirm that I understand these picture requirements.</label>
                </div>
                
                <smallbutton @click="done()" toplabel='Confirm' buttonclass="button buttonhighlight" />
               
                </form>
            </div>
            
        </template>
      </actionmodal>
</template>

<script>
import actionmodal from '../actions/actionmodal.vue'
import smallbutton from '../controls/button.vue'
export default {
     name:'confirmpic',
     components:{
         actionmodal,
         smallbutton
     },
     props:{
        photoRequirements:{
            type:String,
            required:true,
        },
     },
     methods:{
         done(){
             if(this.$refs.checkme.checked==false){return}
             if(this.$refs.confirmform.checkValidity){
                 this.$emit('accept')
                 this.$refs.mymodal.closeModal()
             }
         },
         openModal() {
            this.$refs.mymodal.openModal()
        },
        closeModal() {
            this.$refs.mymodal.closeModal()
        }
     },
     
}
</script>

<style>
.main{
    text-align:left;
}
.pictitle{
    font-weight: bold;
}
.checklabel{
    font-size:medium;
    margin-left:5px;
}
form{
    text-align: center;
}
</style>
