<template>
    <div class="vertFlex">
         
        <div class="vertFlex">
            <div v-for="item in itemList" :key="item.id" :value="item.id">
            <tallbutton :toplabel="item.name" @click="select(item.guid)" />
            </div>
            <label v-if="noneAvailable">No eligible product types are available for your account</label>
        </div>
     
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import tallbutton from '../controls/tallbutton.vue'
export default {
    name:'producttype',
    mixins:[datamethods],
    components:{
        tallbutton
    },
    data(){
        return{
            itemList:[]
        }
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'message',
            'pleaseWait'
        ]),
        noneAvailable(){
            return this.itemList.length==0 && !this.pleaseWait
        }
    },
    methods:{
        async getList(){
            let req = {
                walletId:this.loggedInUser.walletId,
                locationId:this.loggedInUser.LocationId,
                personId:this.loggedInUser.user_personId
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'producttypes')

            //handle response
            if(response.Successful){
            this.itemList = response.productTypeList
            if(this.itemList.length==1){
                //select the type to skip it
            
                this.select(this.itemList[0].guid)
                
            }
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        select(productTypeGuid){
            this.$emit('select',productTypeGuid)
        }
    },
    mounted(){
        this.getList()
    }

}
</script>

<style>

</style>