<template>
  <modal ref="modalName">
        <template v-slot:header>
          <div class="horizFlex">
            <p class="subtitlebold">Add Payment Method</p>
            <img alt="cardlogo" :src="cardLogoPath"  class="logocard">
          </div>
          
        </template>

        <template v-slot:body>
          <p class="subtitle"> {{confirmMessage}}
            If you have a transaction or daily limit less than this transaction amount, please contact your credit card company or bank to pre-approve this transaction before submitting payment.
            Your school's address is: {{locationAddress}}. Your receipt will remain on file for a 12 month period and a copy of your 
            receipt can be provided by contacting {{locationEmail}}.</p>
       
          <p class="subtitleBold"> 
            When you click “I Accept,” you will be redirected to the Card Processor's Hosted Payments secure card entry site. 
            Please enter your card number, expiration date and security code. Then, wait for a confirmation message 
            that will bring you back to SchoolVision to complete the funding of your student school account(s).</p>
        
          <div>
            <button class="tallButton buttonprimary" @click="$refs.modalName.closeModal()">I Decline</button>
            <button class="tallButton buttonprimary"  @click="$emit('accept')">I Accept</button>
          </div>
        </template>
      </modal>
</template>

<script>
import modal from '../controls/modal.vue'
export default {
    name:'newtokenmodal',
    components:{
        modal
    },
    data(){
        return{
            cardLogoPath:require('@/assets/card.jpg'),
            cardStorageDate:'',
            locationPhone:window.LOCATION_PHONE,
            locationAddress:window.LOCATION_ADDRESS,
            locationEmail:window.LOCATION_EMAIL,
            defaultConsentMessage:'By clicking I Accept, you are providing consent to SchoolVision.net to securely store your payment method information to be used for the funding of your purchases. All notifications regarding this account will be sent by email from SchoolVision, including any changes to terms and conditions. Your credential will only be used for the student school account(s) and only for the purchases you specify. All transactions will be funded in US Dollars.'
        }
    },
    computed:{
        
        confirmMessage(){
            if(window.PAYMENT_CONSENT_MESSAGE==undefined){
                return this.defaultConsentMessage
            }else if(window.PAYMENT_CONSENT_MESSAGE==''){
              return this.defaultConsentMessage
            }else{return window.PAYMENT_CONSENT_MESSAGE}
        }
    },
    methods:{
      closeModal() {
        this.$refs.modalName.closeModal()
      },
    openModal() {
        this.$refs.modalName.openModal()
      }
    }
}
</script>

<style>
.logocard{
  max-width: 100%;
    max-height:75px;
    margin-left:80px;
}
.horizFlex{
  display:flex;
  flex-direction: row;
  margin:5px;
}
</style>