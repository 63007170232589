<template>
    <div class="vertFlex">
        <div class="vertFlex">
            <tallbutton toplabel="Sign In" @click="signin()" />
            <tallbutton toplabel="Sign Up" @click="signup()" />
        </div>
     
    </div>
</template>

<script>
import tallbutton from '../controls/tallbutton.vue'
export default {
    name:'signinsignup',
    components:{
        tallbutton
    },

    methods:{
        signin(){
            this.$emit('signin')
        },
        signup(){
            this.$emit('signup')
        },

        
    },

}
</script>

<style>

</style>