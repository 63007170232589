<template>
    <div class="vertFlex" >
       

        <div class="vertFlex" >
            <p class="bold">Now it’s time to start uploading the documents for your file.</p>
            <div class="leftjustify">
                <p>1. {{ NEXTSTEPS_STEP1TEXT }}</p>
                <!-- Go to <a :href="MOBILEIDURL" target="_blank" >{{MOBILEIDURL}}</a> -->
                <p>2. {{ NEXTSTEPS_STEP2TEXT }}</p>
                <p>3. {{NEXTSTEPS_STEP3TEXT}}</p>
                <p>4. {{NEXTSTEPS_STEP4TEXT}}</p>
            </div>
            
            <p>Someone will be reaching out to you soon about the status of your enrollment!</p>
            <p>Have a great day!</p>

        </div>    

        <div>
            <!-- get person and then show it -->
            <tallbutton :toplabel="confirmText" @click="confirm()"/>
        </div> 
    
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import tallbutton from '../controls/tallbutton.vue'
export default {
    name:'nextsteps',
    components:{
        tallbutton
    },
    mixins:[],
     props:{
        
       
        confirmText:{
            type:String,
            default:"Confirm"
        },
        skipIfNull:{
            type:Boolean,
            default:true
        },
 
    },
    computed:{
        ...mapFields([
            'message',
            'pleaseWait',
            'accountTransactionId',
            'person'
        ]),
        MOBILEIDURL(){
            if(window.MOBILEID_URL==undefined){return ""}
            else{return window.MOBILEID_URL}
        },
        NEXTSTEPS_STEP3TEXT(){
            if(window.NEXTSTEPS_STEP3TEXT==undefined){return ""}
            else{return window.NEXTSTEPS_STEP3TEXT}
        },
        NEXTSTEPS_STEP4TEXT(){
            if(window.NEXTSTEPS_STEP4TEXT==undefined){return ""}
            else{return window.NEXTSTEPS_STEP4TEXT}
        },
        NEXTSTEPS_STEP1TEXT(){
            if(window.NEXTSTEPS_STEP1TEXT==undefined){return ""}
            else{return window.NEXTSTEPS_STEP1TEXT}
        },
        NEXTSTEPS_STEP2TEXT(){
            if(window.NEXTSTEPS_STEP2TEXT==undefined){return ""}
            else{return window.NEXTSTEPS_STEP2TEXT}
        },
    },
    data(){
        return{
            transactionSummary:null
        }
    },
    methods:{
         
        confirm(){
            this.$emit('confirm')
        }
    },
    mounted(){
        this.GetTransSummary()
    }
    

}
</script>

<style>
.bordered{
  background-color: white;
  border-radius:10px;
  margin:10px;
  padding:10px;
}
.multilinelabel{
  white-space: pre-wrap;
  max-width:600px;
  font-size:x-large;
}
</style>