export default{
    
    filters:{
        toCurrency(value) {

          var formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumSignifantDigits: 2
          });
          return formatter.format(value);
            
        },
        formatDate(dateString) {
            const date = new Date(dateString);
                // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
        },
        formatPhone(phn) {
           // console.log('formatph ' + phn)
            if(phn==null){return ''}
            if(phn==''){return ''}
            phn=phn.replace('-','').replace('(','').replace(')','').replace(' ','')
           // console.log('cleannum ' + phn)
    
            if(phn.length==11 && phn.substring(0,1)=='1'){phn=phn.substring(1)}
    
            phn = "(" + phn.substring(0,3) + ")" + phn.substring(3,6) + "-" + phn.substring(6);
            return phn
                
        },
    },
    methods:{
        capitalize(string){
            return string.charAt(0).toUpperCase() + string.slice(1);
          },
    }
}