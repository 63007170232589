<template>
    <footer>
        <div class="customfoot" v-if="customFooter!=''">
            <a class=" textlink" :href="locationPhoneForLink" target="_blank" >{{phone}}</a>
            <br>
            <a class=" textlink" :href="locationEmailForLink" target="_blank">{{email}}</a>
            <p class=" textlink">{{customFooter}}</p>
        </div>
        <br>
        <!-- <a class="footerlink" href="https://www.schoolvision.net" target="_blank" tabindex="-1">
          <img alt="school logo" :src="logoPath"  class="footerlogo" v-if="logoPath!=''" >
        </a> -->
         <!-- <img alt="school logo" :src="logoPath"  class="footerlogo" v-if="logoPath!=''" > -->
        <label for="">{{copywrite}}</label>
        <Linkbutton :toplabel="privacyPolicyTitle" @click="togglePrivacy()"/>
        <label v-if="showPrivacy">Privacy Policy: To protect your data and your privacy, only information required to make payments 
            to your student school account(s) and to post your course progress and/or graduation information to your employer, and/or 
            local and federal government (FMCSA) will be collected in this transaction, including your first and last name, mobile phone number, 
            email address, driver license/permit number, all or part of your social security number, birthdate, and address; in addition to your 
            checking account information (bank account number & routing number) and/or your credit card information (card number & expiration date). 
            Your data will never be sold or shared with anyone, nor will your bank account or credit card information be visible to anyone. 
            Your credit card information will be strictly entered on the {{processorName}} PCI-compliant Hosted Payments site with no visibility 
            to the school or SchoolVision. SchoolVision is a division of Newman Consulting, LLC, located in North Salt Lake, UT, and online at 
            <a href="https://www.schoolvision.net" target="_blank" >www.schoolvision.net</a>. 
          {{processorDetail}} is located online at <a :href='processorURL' target="_blank" >{{processorURLName}}</a>. 
          All web traffic involving your credit card information is protected by SSL/TLS security. Web hosting services are provided 
          by Microsoft Azure which can be reached online at <a href="https://www.microsoft.com" target="_blank" >www.microsoft.com/azure.</a></label>
        
    </footer>
</template>

<script>
import Linkbutton from './controls/linkbutton.vue';
export default {
    name: "svfooter",
    components:{
        Linkbutton
    },
    data() {
        return {
            logoFilePath:require('@/assets/MC_Mobile.png'),
            poweredByText:'Powered by School-Payments',
            privacyLinkText: 'For our privacy policy please visit',
            showPrivacy:false,
            customFooter:window.CUSTOM_FOOTER,
            phone:window.LOCATION_PHONE,
            email:window.LOCATION_EMAIL,
            locationPhoneForLink:'tel:+' + window.LOCATION_PHONE,
          locationEmailForLink:'mailto:' + window.LOCATION_EMAIL,
          copywrite:window.FOOTER_COPYRIGHTLABEL,
          processorName:window.PAYMENT_PROCESSOR_NAME,
          processorDetail:window.PAYMENT_PROCESSOR_DETAIL,
          processorURL:window.PAYMENT_PROCESSOR_URL,
          processorURLName:window.PAYMENT_PROCESSOR_URLNAME
        };
    },
    computed:{
        logoPath(){
            return this.logoFilePath
        },
        privacyPolicyTitle(){
            if(this.showPrivacy){
                return "Hide Privacy Policy"
            }else{return "Show Privacy Policy"}
        }
    },
    methods:{
        togglePrivacy(){
            this.showPrivacy=!this.showPrivacy
        }
    }
}
</script>

<style>
footer{
    grid-area:footer;
    padding:10px;
    display:flex;
    flex-direction:column;
    text-align: center;
    font-size: small;
    color:var(--text-color);
}
.footerlink:link{
    color:var(--text-color);
}
.footerlink:visited{
    color:var(--text-color);
}
.footerlink:hover{
    color:var(--button-color);
}
.footerlogo{
  width:180px;
  margin:auto;
}
.footertextlight{
  color:var(--light-text-color);
  width:70%;
}
.textlink{
  margin:5px;
  color:var(--text-color);

}
.customfoot{
   
}
</style>