<template>
    <div class="vertFlex" >
       
        <div class="vertFlex">
            <div >
                <div class="bordered" >
                    <div class="vertFlexTop" v-if="transactionSummary!=null">
                        <div v-html="transactionSummary" class="multilinelabel"></div>
                    </div>  
                    <div class="vertFlexTop" v-if="transactionSummary==null">
                        <div class="bordered" v-for="account in accountList" :key="account.ID" :value="account.ID">
                            <div class="vertFlexTop">
                                <label class="listtextbold">
                                    {{AmountLabel}} {{account.Amount | toCurrency}}
                                </label>
                                <label class="listtextsub" v-if="account.TaxAmount!='0'">
                                    {{TaxLabel}} {{account.TaxAmount | toCurrency}}
                                </label>
                                <label class="listtextsub" v-if="account.FeeAmount!='0'">
                                    {{FeeLabel}} {{account.FeeAmount | toCurrency}}
                                </label>
                                <label class="listtextbold">
                                    Total: {{totalAmount(account) | toCurrency}}
                                </label>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <!-- get person and then show it -->
            <tallbutton toplabel="Confirm" @click="submitFunds()"/>
        </div> 
        
    </div>
</template>

<script>
import format from '../../style/format.js'
import tallbutton from '../controls/tallbutton.vue'
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
export default {
    name:'accountsummary',
    mixins:[datamethods, format],
    components:{
        tallbutton
    },
     props:{
         selectedPaymentAmount:{
             type:Number,

         },
         selectedWalletTokenId:{
             type:Number,
         },
    
       
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'message',
            'pleaseWait',
            'isUrlProduct',
            'isAccountProduct',
            'accountTransactionId',
            'person',
            'selectedProductId'
        ]),
        
        AmountLabel(){
            if(window.AMOUNT_LABEL==undefined){return "Amount:"}
            else{return window.AMOUNT_LABEL + ":"}
        },
        FeeLabel(){
            if(window.FEE_LABEL==undefined){return "Service Fee:"}
            else{return window.FEE_LABEL + ":"}
        },
        TaxLabel(){
            return "Tax: "
        }
    },
     data(){
        return{
            accountList:[],
            transactionSummary:null
        }
     },
    methods:{
        totalAmount(account){
            return account.Amount + account.FeeAmount + account.TaxAmount
        },
        async GetAccounts(){
            let amt = this.selectedPaymentAmount;
            let token = this.selectedWalletTokenId;

            //validate
            if(this.loggedInUser.user_personId==0){return;}
            //create req
            let req = {
                locationId:'0',
                personId:this.loggedInUser.user_personId,
                walletId:this.loggedInUser.walletId
            }
            //call
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'account')
            //handle response
            if(response.Successful){
                this.accountList=response.AccountList;
                this.accountList.forEach(myFunction3)
                function myFunction3(item, index, arr) {
                    if(arr[index].Amount==undefined){arr[index].Amount=amt}
                    arr[index].TokenID=token
                    if(arr[index].FeeAmount==undefined){arr[index].FeeAmount='0'}
                    if(arr[index].TaxAmount==undefined){arr[index].TaxAmount='0'}
                }   

                //first, we need to check for tax... then we'll go to fees
                this.GetTax()
                //this.GetFees();//now we can add the fees!
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        async GetTax(){
 
            //create req
            let req = {
                Product:{
                    id:this.selectedProductId
                }
                
            }
            //call
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'gettax')
            //handle response
            if(response.Successful){
                var taxamount = response.responseDouble
                console.log(taxamount)
                //add the tax to the record  
                this.accountList.forEach(myFunction3)
                function myFunction3(item, index, arr) {
                    arr[index].TaxAmount=taxamount
                } 
              
                this.GetFees();//now we can add the fees!
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
      async GetFees(){
 
        let req = {
            initialAccountFundList:this.accountList
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response = await this.callAPI(req,'getfee')
        //handle response
        if(response.Successful){
          this.accountList=response.initialAccountFundList

          this.getTransactionString('accountsummary',true)
          
        }else{
          this.message = response.Message
        }
        this.pleaseWait=false;
      },
       async getTransactionString(goafter,ifnullgoahead){
            let req = {
                accountTransactionId:this.accountTransactionId
            }

            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'transsummary')

            //handle response
            if(response.Successful){
            
                this.transactionSummary= response.StringResponse
                this.replaceAddress()
                if(this.transactionSummary==null){
                    
                    //this.submitFunds()
                }
            }
            else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        replaceAddress(){
            if(this.transactionSummary==null){return}
            if(this.transactionSummary.includes('[Address]')){
                var fullAddress = this.person.AddressLine1 + ' ' + this.person.City + ', ' + this.person.StateName + ' ' + this.person.ZipCode
                this.transactionSummary=this.transactionSummary.replace('[Address]',fullAddress)
            }
        },
      async submitFunds(){
          console.log('submitfunds')
        //setup request
        let req = {
            walletId:this.loggedInUser.walletId,
            accountId:this.loggedInUser.user_accountId,
            initialAccountFundList:this.accountList,
            accountTransactionId:this.accountTransactionId,
            locationId:this.loggedInUser.locationId
        }
        //call api
        this.$store.dispatch('beforeAPI');
        var response
        if(this.isUrlProduct || this.isAccountProduct){
            response = await this.callAPI(req,'paymenttoaccountlistenroll')
        }else{
            response = await this.callAPI(req,'paymenttoaccountlist_productenroll')
        }
        
        //handle response
        if(response.Successful){
          this.accountList=response.initialAccountFundList
          this.accountTransactionId = response.initialAccountFundList[0].initialTransactionID
          this.$emit('fundssubmitted')
        }else{
          this.message="Your transaction failed to process."
        }
        this.pleaseWait=false;
      },
        
    },
    mounted(){
        this.GetAccounts()
    }

}
</script>

<style>
.bordered{
  background-color: white;
  border-radius:10px;
  margin:10px;
  padding:10px;
}
.multilinelabel{
  white-space: pre-wrap;
  max-width:600px;
  font-size:x-large;
}
.listtextbold{
    font-weight: bold;

}
.listtextsub{
    font-size:smaller;
}
</style>