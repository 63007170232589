<template>
  <div >
       <div v-if="!loggedIn">
         <img alt="school logo" :src="LogoPath"  class="loginlogo" >
       </div>
       <div v-if="loggedIn" class="horizFlexStretch">
          <div class="vertFlexTop leftjustified">
            <img alt="school logo" :src="LogoPath"  class="logo" v-if="LogoPath!=''">
            <label class="headertextbold" v-if="ShowName">{{loggedInUser.LocationName}}</label>
         </div>
         <div class="vertFlexTop rightjustified" v-if="requireLogin">
           <label class="headertextbold">{{loggedInUser.name}}</label>
           <label class="headertext">{{loggedInUser.email}}</label>
           <label class="headertext">ID#: {{loggedInUser.code}}</label>
           <linkbutton class="logoutButton" toplabel="Logout" @click="logout()" />
         </div>
       </div>
        
    </div>
</template>

<script>
 import {mapFields} from 'vuex-map-fields'
 import linkbutton from './controls/linkbutton.vue'
export default {
    name:'svheader',
    components:{
        linkbutton
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'loggedIn',
            'logoImageBasePath',
            'logoOverride',
            'requireLogin'
        ]),
        LogoPath(){
            if(this.logoOverride!=''){
                return this.logoImageBasePath + this.logoOverride
            }else if(window.USE_LOCATION_NAME_FOR_LOGO && this.loggedIn){
                return './../' + this.loggedInUser.LocationName + '.JPG'
            
            }else{
                return './../' + window.SCHOOL_LOGO
            }
            
        },
        ShowName(){
            if(window.SHOW_SCHOOL_NAME==undefined){return true}
            else{return window.SHOW_SCHOOL_NAME}
        }
    },
    methods:{
        logout(){
            this.$emit('logout')
        }
    }
}
</script>

<style>

.vertFlexTop{
  display:flex;
  flex-direction: column;
  margin:5px;
  align-items: top;
}
.logo{
      height:70px;
  }
  .loginlogo{
      height:80px;
      margin-right:0px;
      margin-top:10px;
    }
</style>