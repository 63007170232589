<template>
    <div class="vertFlex">
        
        <div class="vertFlex">
            <div v-for="item in itemList" :key="item.id" :value="item.id">
                <button class="productButton buttonprimary" type="button" @click="select(item)" >
                        <div >
                            <p class="productbuttontext bold">{{productDisplayName(item)}}</p>
                            <p class="productbuttontext" v-if="showProductDescription">{{item.lineName}}</p>
                            <p class="productbuttontext" v-if="showProductDescription">{{item.description}}</p>
                        </div>       
                </button>
                
               
            </div>
            <label v-if="noneAvailable">No eligible products are available for your account</label>
        </div>
        
    </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import datamethods from '../../services/datamethods.js'
import tallbutton from '../controls/tallbutton.vue'
import format from '../../style/format.js'
export default {
    name:'product',
    mixins:[datamethods,format],
    components:{
        tallbutton
    },
    data(){
        return{
            itemList:[],
            
        }
    },
    props:{
        typeGUID:{
            type:String,
            
        },
    },
    computed:{
        ...mapFields([
            'loggedInUser',
            'message',
            'pleaseWait'
        ]),
        noneAvailable(){
            return this.itemList.length==0 && !this.pleaseWait
        },
        showProductDescription(){
            if(window.SHOW_PRODUCT_DESCRIPTION==undefined){return true}
            else{return window.SHOW_PRODUCT_DESCRIPTION}
        },
        requireAccountForSale(){
            //def false
            if(window.REQUIRE_ACCOUNT_FOR_SALE==undefined){return false}
            else{return window.REQUIRE_ACCOUNT_FOR_SALE}
        }
    },
    methods:{
        async getList(){
            let req = {
                walletId:this.loggedInUser.walletId,
                locationId:this.loggedInUser.LocationId,
                productTypeGUID:this.typeGUID
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'products')

            //handle response
            if(response.Successful){
                this.itemList = response.productList
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
        },
        
        async select(product){
            if(this.loggedInUser.user_accountId==0 && this.requireAccountForSale){
                this.message="Your account is not setup. Please contact your administrator before purchasing online."
                return
            }
            //send in to start transaction
            let req = {
                walletId:this.loggedInUser.walletId,
                accountId:this.loggedInUser.user_accountId,
                locationId:this.loggedInUser.LocationId,
                restrictToProductTypeGlobalKey:this.typeGUID,
                restrictToProductGlobalKey:product.guid,
                price:product.price
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'productsale')

            //handle response
            if(response.Successful){
              
                this.$emit('select',product,response.ResponseInteger)
            }else{
                this.message = response.Message
            }
            this.pleaseWait=false;
            
        },
          productDisplayName(product){
            var name = product.name
            if(product.price!=0){
            name = name +  " - " + this.$options.filters.toCurrency(product.price)
            }
            return name
        },
    },
    mounted(){
        this.getList()
    }

}
</script>

<style>
.productButton{
    white-space:pre-wrap;
    color:var(--light-text-color);
    border: none;
    border-radius:10px;
    min-height:90px;
    padding:5px;
    margin:10px;

}
.productbuttontext{
  margin:0px;
}
.bold{
  font-weight:bold;
  font-size:larger;
}
</style>